import { render, staticRenderFns } from "./ListUsersWorkspace.vue?vue&type=template&id=6b84d486&scoped=true"
import script from "./ListUsersWorkspace.vue?vue&type=script&lang=js"
export * from "./ListUsersWorkspace.vue?vue&type=script&lang=js"
import style0 from "./ListUsersWorkspace.vue?vue&type=style&index=0&id=6b84d486&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b84d486",
  null
  
)

export default component.exports