<template>
  <b-card>
    <b-row class="justify-content-between d-flex mb-5">
      <div>
        <p class="p-1">
          <strong>Área de Trabalho:</strong>
          <span> {{ workspace ?  workspace.workspace_title : '' }}</span>
        </p>
      </div>
      <b-button
        v-if="items.length > 0"
        class="mb-2 mr-2"
        variant="primary"
        @click="$router.push(`/link-user-workspace/${$route.params.id}`)"
      >
        Vincular novo usuário
      </b-button>
    </b-row>
    <b-table
      responsive="sm"
      small
      :items="items"
      :fields="fields"
      class="w-100"
      show-empty
    >
      <template v-slot:cell(actions)="{ item }">
        <actions-users-workspace :item="item" />
      </template>

      <template v-slot:cell(access)="{ item }">
        <b-row class="colum-desactivate justify-content-between d-flex p-1">
          <!-- <b-form-checkbox
            id="checkbox-1"
            v-model="item.access.is_admin"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Admin
          </b-form-checkbox> -->
          <b-form-checkbox
            id="checkbox-1"
            v-model=" item.access.is_manager"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Gerente
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-1"
            v-model="item.access.is_transaction_manager"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Financeiro
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-1"
            v-model="item.access.is_blocked"
            name="checkbox-1"
            value="true"
            unchecked-value="false"
          >
            Inativo
          </b-form-checkbox>
        </b-row>
      </template>
      <template #empty>
        <b-alert show variant="primary">
          <p class="text-center">
            <br />
            Não existem <strong>Usuários vinculados</strong> a essa Área de
            trabalho!
          </p>
          <div class="justify-content-center d-flex mt-2">
            <b-button
              v-if="items.length < 1"
              variant="primary"
              @click="$router.push(`/link-user-workspace/${$route.params.id}`)"
            >
              Vincular novo usuário
            </b-button>
          </div>
          <hr />
        </b-alert>
      </template>
    </b-table>
    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPage"
      aria-controls="my-table"
    ></b-pagination>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de usuários: {{ totalRows }} </strong>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BTable,
  BFormCheckbox,
  BAlert,
  BPagination,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ActionsUsersWorkspace from './ActionsUsersWorkspace.vue';
export default {
  name: 'list-user-workspace',
  components: {
    BCard,
    BButton,
    BRow,
    BTable,
    BFormCheckbox,
    ActionsUsersWorkspace,
    BAlert,
    BPagination
  },
  data() {
    return {
      items: [],
      workspace: null,
      fields: [
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        },
        {
          key: 'username',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'access',
          label: 'Permissões',
          sortable: true,
        },
      ],
      perPage: 30,
      rows: 0,
      currentPage: 1,
      totalRows: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    currentUser() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.$store.getters.currentWorkspace.id
      );
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  methods: {
    getPage(val) {
      this.getAllUsers(val);
    },
    init() {
      this.getAllUsersWorkspace();
      this.getWorkspace();
    },
    getWorkspace() {
      this.$store
        .dispatch('getWorkspace', { id: this.$route.params.id })
        .then((resp) => {
          this.workspace = resp;
        
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar àrea de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    getAllUsersWorkspace() {
      this.$store
        .dispatch('getAllUsersWorkspaces', {
          id: this.$route.params.id,
          page: this.currentPage,
          perPage: this.perPage,
          inactive: false,
        })
        .then((resp) => {
          this.items = resp.data;
          this.totalRows = resp.total
        });
    },
  },
};
</script>

<style scoped>
.colum-desactivate {
  pointer-events: none !important;
}
</style>
